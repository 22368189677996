import { GoogleAuthProvider } from "firebase/auth";

export const guData = {
    "consulting" : [
        {
            title: "Beratungsangebote für studierende Eltern",
            URL: "https://www.uni-frankfurt.de/f7/beratung-fur-studierende-mit-familienverantwortung-113940452"
        }, 
        {
            title: "Mutterschutz",
            URL: "https://www.uni-frankfurt.de/f7/mutterschutz-an-der-goethe-universitat-71461929"
        },
         {
            title: "Pflege von Angehörigen",
            URL: "https://www.uni-frankfurt.de/f7/pflege-von-angehorigen-113940428"
         }
    ],

    "childcare" : [
        {
            title: "Kitas an der Goethe-Universität",
            URL: "https://www.uni-frankfurt.de/41010854/Regul%C3%A4re_Kinderbetreuung_an_der_Goethe_Universit%C3%A4t_Frankfurt"
        },
        {
            title: "Anmeldung für Kinder von Studierenden",
            URL: "https://www.uni-frankfurt.de/f7/regulare-kinderbetreuung-an-der-goethe-universitat-frankfurt-41010854"
        },
        {
            title: "Kinderbetreuung in Frankfurt",
            URL: "https://www.uni-frankfurt.de/f7/kinderbetreuungseinrichtungen-in-frankfurt-41010867"
        },
        {
            title: "Flexible Kinderbetreuung",
            URL: "https://www.uni-frankfurt.de/f7/flexible-kinderbetreuung-41011773"
        }, 
        {
            title: "Ferienbetreuung",
            URL: "https://www.uni-frankfurt.de/f7/ferienbetreuung-41012038"
        }
    ],

    "finances": [
        {
            title: "Unterstützung für Studierende",
            URL: "https://www.uni-frankfurt.de/f7/ein-studium-mit-familie-finanzieren-113940465"
        },
        {
            title: "Vor und nach der Geburt",
            URL: "https://www.uni-frankfurt.de/f7/ein-studium-mit-familie-finanzieren-113940465#Elterngeld"
        },
        {
            title: "Unterstützung bei geringem Einkommen",
            URL: "https://www.uni-frankfurt.de/f7/ein-studium-mit-familie-finanzieren-113940465#ALGII"
        }
    ],

    "legal" : [
        {
            title: "Studium mit Kind / Pflegeverantwortung an der Goethe-Universität",
            URL: "https://www.uni-frankfurt.de/f7/rechtliche-rahmenbedingungen-fur-studierende-eltern-113940482#Pflegeverantwortung"
        },
        {
            title: "Beurlaubung",
            URL: "https://www.uni-frankfurt.de/f7/rechtliche-rahmenbedingungen-fur-studierende-eltern-113940482#Beurlaubung"
        },
        {
            title: "Elternzeit",
            URL: "https://www.uni-frankfurt.de/f7/rechtliche-rahmenbedingungen-fur-studierende-eltern-113940482#Elternzeit"
        },
        {
            title:"Mutterschutz",
            URL: "https://www.uni-frankfurt.de/f7/rechtliche-rahmenbedingungen-fur-studierende-eltern-113940482#Mutterschutz"
        },
        {
            title: "Teilzeitstudium",
            URL: "https://www.uni-frankfurt.de/f7/rechtliche-rahmenbedingungen-fur-studierende-eltern-113940482#Teilzeitstudium"
        }
    ],

    "kidsCampus": [
        {
            title:"Familienbewusste Infrastruktur",
            URL: "https://www.uni-frankfurt.de/f7/mit-kind-am-campus-113940415#Infrastruktur"
        },
        {
            title:"FamilyPlus-Card",
            URL: "https://www.uni-frankfurt.de/f7/familyplus-card-49967114"
        },
        {
            title:"Newsletter FamilyPlus",
            URL: "https://www.uni-frankfurt.de/f7/mit-kind-am-campus-113940415#Newsletter"
        },
        {
            title:"Netzwerk \"Goethe-kids\" auf facebook",
            URL: "https://www.uni-frankfurt.de/f7/mit-kind-am-campus-113940415#facebook"
        },
    ],

    "specificInfo": [
        {
            title:"Alleinerziehende",
            URL: "https://www.uni-frankfurt.de/f7/zielgruppenspezifische-informationen-116374221#Alleinerziehende"
        },
        {
            title:"(Werdende) Väter",
            URL: "https://www.uni-frankfurt.de/f7/zielgruppenspezifische-informationen-116374221#Vaeter"
        },
        {
            title:"Gleichgeschlechtliche und queere Eltern",
            URL: "https://www.uni-frankfurt.de/f7/zielgruppenspezifische-informationen-116374221#LIBS"
        },
        {
            title:"Internationale",
            URL: "https://www.uni-frankfurt.de/f7/zielgruppenspezifische-informationen-116374221#Internationale"
        },
        {
            title:"Auslandstudium mit Kind",
            URL: "https://www.uni-frankfurt.de/f7/zielgruppenspezifische-informationen-116374221#Auslandsstudium"
        },
        {
            title:"Angebote für Schüler*innen",
            URL: "https://www.uni-frankfurt.de/f7/zielgruppenspezifische-informationen-116374221#Angebote"
        },
    ]
}