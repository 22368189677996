import axios from "axios";
import io from "socket.io-client";
import {appConfig} from "../config/appConfig";

export const API_URL = appConfig.apiUrl;
export const socket = io.connect(API_URL);

const api = axios.create({
  baseURL: API_URL,
});

export const getData = async (endPoint) => {
  try {
    const { data } = await api.get(endPoint);
    return data;
  } catch (error) {
    return error;
  }
};

export const postData = async (endPoint, formData) => {
  try {
    const { data } = await api.post(endPoint, formData);
    return data;
  } catch (error) {
    return error;
  }
};
