import React, {useEffect, useState} from "react"
import {Link, useLocation, useNavigate} from "react-router-dom"
import {auth} from "../Firebase"
import {useUserAuth} from "../context/UserAuthContext"
import {useTranslation} from "react-i18next"
import {socket} from "../helpers/HttpService";


/**
 * This is the Sidebar which is fixed vertically on the left underneath the header
 * @returns Sidebar component with icons for navigation routes
 */

function Sidebar() {
    const {user} = useUserAuth();
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const [notification, setNotification] = useState(false);

    useEffect(() => {
        socket.on("UPDATE", (msg) => {
            console.log("UPDATE in SIDEBAR: ", msg)
            if (window.location.pathname !== "/chats" && msg.receiver === user.uid) {
                setNotification(true)
            }
        })
    }, [])

    const signout = () => {
        auth.signOut();
        navigate("/login");
    };

    return (
        <aside className="sidebar">

            <div className="container-icons">
                {(user.email?.includes("@stud.fra-uas.de") && !user.email?.includes("test1")) || user.email?.includes("@united-parents.de") ?
                    <div className="box-icon">
                        <Link to="/home">
                            <span className="material-icons">home</span>
                            <p>{t("homepage")}</p>
                        </Link>
                    </div> :
                    user.email?.includes("@gmx.net") || user.email?.includes("@uni-frankfurt.de") || user.email?.includes("test1@stud.fra-uas.de") ?
                        <div className="box-icon">
                            <Link to="/Ghome">
                                <span className="material-icons">home</span>
                                <p>{t("homepage")}</p>
                            </Link>
                        </div> :
                        <div></div>
                }

                {user.email?.includes("@united-parents.de") ? <div></div> : <div className="box-icon">
                    <Link to="/my-profile">
                        <span className="material-icons">account_circle</span>
                        <p>{t("myprofile")}</p>
                    </Link>
                </div>}

                {user.email?.includes("@united-parents.de") ? <div></div> : <div className="box-icon">
                    <Link to="/search-user">
                        <span className="material-icons">map</span>
                        <p>{t("searchuser")}</p>
                    </Link>
                </div>}

                {user.email?.includes("@united-parents.de") ? <div></div> : <div className="box-icon">
                    <Link className="chat" to="/chats" onClick={() => setNotification(false)}>
                        <span className="material-icons">forum</span>
                        <p>{t("chats")}</p>
                        {notification ? <div className="notification"></div> : null}
                    </Link>
                </div>}

                <div className="box-icon">
                    <Link
                        to={user.email?.includes("@stud.fra-uas.de") || user.email?.includes("@united-parents.de") ? "/help-finances" : "/Ghelp-finances"}>
                        <span className="material-icons">contact_support</span>
                        <p>{t("helpfinances")}</p>
                    </Link>
                </div>

                {user.email?.includes("@united-parents.de") ? <div></div> : <div className="box-icon">
                    <Link to="/settings">
                        <span className="material-icons">settings</span>
                        <p>{t("settings")}</p>
                    </Link>
                </div>}

                <div className="box-icon">
                    <a href="#" onClick={signout}>
                        <span className="material-icons">logout</span>
                        <p>{t("logout")}</p>
                    </a>
                </div>
            </div>
        </aside>
    );
}

export default Sidebar;
