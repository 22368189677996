import React, {useContext, useState, useEffect, createContext} from "react";
import {createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged} from "firebase/auth";
import {auth} from "../Firebase";
import {getAuth, sendEmailVerification} from "firebase/auth";

const userAuthContext = createContext();

export function UserAuthContextProvider({children}) {
    const [user, setUser] = useState("");

    function register(email, password) {
        return createUserWithEmailAndPassword(auth, email, password).then(() => {
            sendEmailVerification(getAuth().currentUser).then(() => {
                alert("Eine E-Mail zur Verfizierung wurde versendet!")
            })
        });
    }

    function login(email, password) {
        return signInWithEmailAndPassword(auth, email, password);
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            console.log(currentUser);
            setUser(currentUser);
        });
        
        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <userAuthContext.Provider
            value={{
            user,
            register,
            login
        }}>
            {children}
        </userAuthContext.Provider>
    );
}

export function useUserAuth() {
    return useContext(userAuthContext);
}
