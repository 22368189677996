import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useUserAuth} from "../context/UserAuthContext";
import Header from "./Header";
import Sidebar from "./Sidebar";

/**
 * Protected routes for auth users
 * @param {*} param0
 * @returns fixed Header & Sidebar component and children=routes for auth users
 */

function ProtectedRoutes({children}) {

    const navigate = useNavigate()

    const GetUser = async () => {
        let {user} = await useUserAuth();

        if (!user) {
            return navigate("/login")
        }
    };

    useEffect(() => {
        GetUser();
    }, []);


    return (
        <div>
            <Header/>
            <Sidebar/>
            <div className="content-surface">{children}</div>
        </div>
    );
}

export default ProtectedRoutes;
