import React, {useEffect, useState} from "react";
import {Card, Form, FormCheck, OverlayTrigger, Spinner, Table} from 'react-bootstrap';
import {Link, useNavigate} from 'react-router-dom';
import axios from "axios";
import {useUserAuth} from "../context/UserAuthContext";
import {storage} from "../Firebase"
import {deleteObject, getDownloadURL, ref, uploadBytes} from "firebase/storage";
import {updateProfile} from "firebase/auth";
import {useTranslation} from "react-i18next";
import {aboutMe} from "../utils/aboutMe";
import {tooltips} from "../utils/tooltips";
import {API_URL} from "../helpers/HttpService";

/**
 * Page where users can edit their profile
 * @returns edited user info (displayed at My Profile page)
 */

function EditProfile() {

    const [a, setA] = useState("A3");
    const [b, setB] = useState("");
    const [c, setC] = useState("");
    const [d, setD] = useState("");
    const [eValue, setE] = useState("");
    const [f, setF] = useState("");
    const [g, setG] = useState("");
    const [h, setH] = useState("");
    const [i, setI] = useState("");
    const [j, setJ] = useState("");
    const [k, setK] = useState("");
    const [l, setL] = useState("");
    const [m, setM] = useState("");
    const [n, setN] = useState("");
    const [o, setO] = useState("");
    const [p, setP] = useState("");
    const [q, setQ] = useState("");
    const [r, setR] = useState("");
    const [s, setS] = useState("");
    const [tValue, setT] = useState("");
    const [u, setU] = useState("");
    const [loading,
        setLoading] = useState(false);
    const [imageUpload,
        setImageUpload] = useState(null);
    const [kidsNumb,
        setKidsNumb] = useState('');
    const [uid,
        setUid] = useState('');
    const [course,
        setCourse] = useState('');
    const [kidsAge,
        setKidsAge] = useState('');
    const [city,
        setCity] = useState('');
    const navigate = useNavigate();
    const [error,
        setError] = useState("");
    const [about,
        setAbout] = useState('');
    const [Plz,
        setPlz] = useState('');
    const {user} = useUserAuth();
    const {t, i18n} = useTranslation()


    const [isSwitchOn,
        setIsSwitchOn] = useState(false);
    const onSwitchAction = () => {
        setIsSwitchOn(!isSwitchOn);
    };

    useEffect(() => {
        setUid(user.uid);
        console.log(user.uid)

        const getUser = async () => {
            try {
                let response = await axios.get(API_URL + `users/${user.uid}`, {

                    headers: {
                        Authorization: 'Bearer ' + user.accessToken
                    }

                })
                setA(response.data?.result.A)
                setB(response.data?.result.B)
                setC(response.data?.result.C)
                setD(response.data?.result.D)
                setE(response.data?.result.E)
                setF(response.data?.result.F)
                setG(response.data?.result.G)
                setH(response.data?.result.H)
                setI(response.data?.result.I)
                setJ(response.data?.result.J)
                setK(response.data?.result.K)
                setL(response.data?.result.L)
                setM(response.data?.result.M)
                setN(response.data?.result.N)
                setO(response.data?.result.O)
                setP(response.data?.result.P)
                setQ(response.data?.result.Q)
                setR(response.data?.result.R)
                setS(response.data?.result.S)
                setT(response.data?.result.T)
                setU(response.data?.result.U)


                setKidsNumb(response.data
                    ?.result.kidsNumb)
                setCourse(response.data
                    ?.result.course)
                setKidsAge(response.data
                    ?.result.kidsAge)
                setAbout(response.data
                    ?.result.about)
                setCity(response.data
                    ?.result.city)
                setPlz(response.data
                    ?.result.plz)
                console.log("this is course" + response.data
                    ?.result.course)
            } catch (err) {
                if (err.response) {
                    console.log(err.response.data)
                    console.log(err.response.status)
                    console.log(err.response.headers)
                } else {
                    console.log(`error ${err.message}`)
                }
            }
        }
        getUser();
    }, [user]);

    const userdata = {
        kidsNumb: kidsNumb,
        kidsAge: kidsAge,
        plz: Plz,
        city: city,
        course: course,
        about: about,
        A: a,
        B: b,
        C: c,
        D: d,
        E: eValue,
        F: f,
        G: g,
        H: h,
        I: i,
        J: j,
        K: k,
        L: l,
        M: m,
        N: n,
        O: o,
        P: p,
        Q: q,
        R: r,
        S: s,
        T: tValue,
        U: u
    };
    //http://0.0.0.0:4000
    //https://united-parents-api-new-l6hcjtgwsq-ew.a.run.app/users/${user.uid}

    const editUser = async () => {
        let res = axios.put(API_URL + `users/${user.uid}`, userdata, {
            headers: {
                Authorization: 'Bearer ' + user.accessToken
            }
        })
        console.log(res);
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setError("")
        try {
            editUser();
            navigate("/my-profile")
        } catch (err) {
            setError(err.message);
        }
    }

    const uploadPics = async () => {
        if (imageUpload == null) {
            setError(t("choosepic"))
            alert(error)
        } else {
            const imageRef = ref(storage, `images/${user.uid}`)

            deleteObject(imageRef).then(() => {
                console.log("image deleted")
            }).catch((err) => {
                console.log(err)
            });

            setLoading(true)
            uploadBytes(imageRef, imageUpload).then(() => {
                alert(t("uploadsuccessful"))
                setLoading(false)
            })

            const imageUrl = await getDownloadURL(imageRef);
            updateProfile(user, {photoURL: imageUrl});
        }
    }

    return (
        <div className="container-contents">
            <div className="box-content edit-profile">
                <Card>
                    <Card.Body>
                        <h1 className="text-center mb-4">{t("editprofile")}</h1>
                        <h2>{t("profilepic")}</h2>
                        <input
                            type="file"
                            onChange={(event) => {
                                setImageUpload(event.target.files[0])
                            }}/>

                        <div className="container-spinner">
                            <button className="button stay" disabled={loading}
                                    onClick={uploadPics}>{t("upload")}</button>
                            {loading
                                ? <div className="box-spinner">
                                    <Spinner animation="border" role="status"></Spinner>
                                    <p>{t("picuploadinprogress")}...</p>
                                </div>
                                : null}
                        </div>

                        <Form onSubmit={handleSubmit}>

                            <Form.Group id="kidsNumb">
                                <OverlayTrigger placement="top" overlay={tooltips.tt_kidsNr}>
                                    <Form.Label>{t("children")}</Form.Label>
                                </OverlayTrigger>
                                <Form.Control
                                    value={kidsNumb}
                                    type="kidsNumb"
                                    placeholder="Wie viele Kinder hast du?"
                                    onChange={(e) => setKidsNumb(e.target.value)}/>
                            </Form.Group>

                            <Form.Group id="kidsAge">
                                <OverlayTrigger placement="top" overlay={tooltips.tt_kidsAge}>
                                    <Form.Label>{t("agechildren")}</Form.Label>
                                </OverlayTrigger>
                                <Form.Control
                                    value={kidsAge}
                                    type="kidsAge"
                                    placeholder="Wie alt sind deine Kinder? "
                                    onChange={(e) => setKidsAge(e.target.value)}/>
                            </Form.Group>

                            <Form.Group id="course">
                                <OverlayTrigger placement="top" overlay={tooltips.tt_course}>
                                    <Form.Label>{t("course")}</Form.Label>
                                </OverlayTrigger>
                                <Form.Control
                                    value={course}
                                    type="course"
                                    placeholder="Studiengang"
                                    onChange={(e) => setCourse(e.target.value)}/>
                            </Form.Group>

                            <Form.Group id="Standort">
                                <OverlayTrigger placement="top" overlay={tooltips.tt_city}>
                                    <Form.Label>{t("city")}</Form.Label>
                                </OverlayTrigger>
                                <Form.Control
                                    value={city}
                                    type="Standort"
                                    placeholder="Standort"
                                    onChange={(e) => setCity(e.target.value)}/>
                            </Form.Group>

                            <Form.Group id="plz">
                                <OverlayTrigger placement="top" overlay={tooltips.tt_plz}>
                                    <Form.Label>{t("zipcode")}</Form.Label>
                                </OverlayTrigger>
                                <Form.Control
                                    value={Plz}
                                    type="Postleitzahl"
                                    placeholder="PLZ: XXXXX"
                                    onChange={(e) => setPlz(e.target.value)}/>
                            </Form.Group>
                            <Form.Group className="mb-3" id="aboutMe">
                                <OverlayTrigger placement="top" overlay={tooltips.tt_aboutme}>
                                    <Form.Label>{t("aboutme")}</Form.Label>
                                </OverlayTrigger>
                                <Form.Control
                                    value={about}
                                    type="aboutMe"
                                    as="textarea"
                                    placeholder={aboutMe.hinttext}
                                    rows={9}
                                    onChange={(e) => setAbout(e.target.value)}/>
                            </Form.Group>

                            <div className="box-buttons">
                                <button className="button go-to" type="submit">{t("save")}</button>

                            </div>
                        </Form>
                        <Link className="button go-to cancel" to="/my-profile">{t("cancel")}</Link>
                    </Card.Body>
                </Card>
            </div>

            <div className="box-content">
                <p>{t("timesforchildcare")}:</p>
                <div className="table-legend">
                    <div className="offer-childcare yellow">{t("available")}</div>
                    <div className="need-childcare pink">{t("inneed")}</div>
                    <div className="nothing grey">{t("neither")}</div>
                </div>
                <Table bordered responsive="sm">
                    <thead>
                    <tr>
                        <th></th>
                        <th>{t("morning")}</th>
                        <th>{t("midday")}</th>
                        <th>{t("evening")}</th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr>
                        <td>{t("monday.abbr")}</td>
                        <td>
                            <Form>
                                <FormCheck type="radio" value="A1" checked={a === "A1"}
                                           className="yellow" name=" A" onChange={e => setA(e.target.value)}/>
                                <FormCheck type="radio" value="A2" checked={a === "A2"} className="pink"
                                           name=" A" onChange={e => setA(e.target.value)}/>
                                <FormCheck type="radio" value="A3" checked={a === "A3"} className="grey"
                                           name=" A" onChange={e => setA(e.target.value)}/>
                            </Form>
                        </td>
                        <td><Form>
                            <FormCheck type="radio" value="B1" checked={b === "B1"} className="yellow"
                                       name=" B" onChange={e => setB(e.target.value)}/>
                            <FormCheck type="radio" value="B2" checked={b === "B2"} className="pink"
                                       name=" B" onChange={e => setB(e.target.value)}/>
                            <FormCheck type="radio" value="B3" checked={b === "B3"} className="grey"
                                       name=" B" onChange={e => setB(e.target.value)}/>
                        </Form></td>
                        <td><Form>
                            <FormCheck type="radio" value="C1" checked={c === "C1"} className="yellow"
                                       name=" C" onChange={e => setC(e.target.value)}/>
                            <FormCheck type="radio" value="C2" checked={c === "C2"} className="pink"
                                       name=" C" onChange={e => setC(e.target.value)}/>
                            <FormCheck type="radio" value="C3" checked={c === "C3"} className="grey"
                                       name=" C" onChange={e => setC(e.target.value)}/>
                        </Form></td>
                    </tr>

                    <tr>
                        <td>{t("tuesday.abbr")}</td>
                        <td>
                            <Form>
                                <FormCheck type="radio" value="D1" checked={d === "D1"}
                                           className="yellow" name=" D" onChange={e => setD(e.target.value)}/>
                                <FormCheck type="radio" value="D2" checked={d === "D2"} className="pink"
                                           name=" D" onChange={e => setD(e.target.value)}/>
                                <FormCheck type="radio" value="D3" checked={d === "D3"} className="grey"
                                           name=" D" onChange={e => setD(e.target.value)}/>
                            </Form>
                        </td>
                        <td><Form>
                            <FormCheck value="E1" checked={eValue === "E1"} type="radio"
                                       className="yellow" name=" E" onChange={e => setE(e.target.value)}/>
                            <FormCheck value="E2" checked={eValue === "E2"} type="radio" className="pink"
                                       name=" E" onChange={e => setE(e.target.value)}/>
                            <FormCheck value="E3" checked={eValue === "E3"} type="radio" className="grey"
                                       name=" E" onChange={e => setE(e.target.value)}/>
                        </Form></td>
                        <td><Form>
                            <FormCheck value="F1" checked={f === "F1"} type="radio" className="yellow"
                                       name=" F" onChange={e => setF(e.target.value)}/>
                            <FormCheck value="F2" checked={f === "F2"} type="radio" className="pink"
                                       name=" F" onChange={e => setF(e.target.value)}/>
                            <FormCheck value="F3" checked={f === "F3"} type="radio" className="grey"
                                       name=" F" onChange={e => setF(e.target.value)}/>
                        </Form></td>
                    </tr>

                    <tr>
                        <td>{t("wednesday.abbr")}</td>
                        <td><Form>
                            <FormCheck value="G1" checked={g === "G1"} type="radio" className="yellow"
                                       name=" G" onChange={e => setG(e.target.value)}/>
                            <FormCheck value="G2" checked={g === "G2"} type="radio" className="pink"
                                       name=" G" onChange={e => setG(e.target.value)}/>
                            <FormCheck value="G3" checked={g === "G3"} type="radio" className="grey"
                                       name=" G" onChange={e => setG(e.target.value)}/>
                        </Form></td>
                        <td><Form>
                            <FormCheck value="H1" checked={h === "H1"} type="radio" className="yellow"
                                       name=" H" onChange={e => setH(e.target.value)}/>
                            <FormCheck value="H2" checked={h === "H2"} type="radio" className="pink"
                                       name=" H" onChange={e => setH(e.target.value)}/>
                            <FormCheck value="H3" checked={h === "H3"} type="radio" className="grey"
                                       name=" H" onChange={e => setH(e.target.value)}/>
                        </Form></td>
                        <td><Form>
                            <FormCheck value="I1" checked={i === "I1"} type="radio" className="yellow"
                                       name=" I" onChange={e => setI(e.target.value)}/>
                            <FormCheck value="I2" checked={i === "I2"} type="radio" className="pink"
                                       name=" I" onChange={e => setI(e.target.value)}/>
                            <FormCheck value="I3" checked={i === "I3"} type="radio" className="grey"
                                       name=" I" onChange={e => setI(e.target.value)}/>
                        </Form></td>
                    </tr>

                    <tr>
                        <td>{t("thursday.abbr")}</td>
                        <td><Form>
                            <FormCheck value="J1" checked={j === "J1"} type="radio" className="yellow"
                                       name=" J" onChange={e => setJ(e.target.value)}/>
                            <FormCheck value="J2" checked={j === "J2"} type="radio" className="pink"
                                       name=" J" onChange={e => setJ(e.target.value)}/>
                            <FormCheck value="J3" checked={j === "J3"} type="radio" className="grey"
                                       name=" J" onChange={e => setJ(e.target.value)}/>
                        </Form></td>
                        <td><Form>
                            <FormCheck value="K1" checked={k === "K1"} type="radio" className="yellow"
                                       name=" K" onChange={e => setK(e.target.value)}/>
                            <FormCheck value="K2" checked={k === "K2"} type="radio" className="pink"
                                       name=" K" onChange={e => setK(e.target.value)}/>
                            <FormCheck value="K3" checked={k === "K3"} type="radio" className="grey"
                                       name=" K" onChange={e => setK(e.target.value)}/>
                        </Form></td>
                        <td><Form>
                            <FormCheck value="L1" checked={l === "L1"} type="radio" className="yellow"
                                       name=" L" onChange={e => setL(e.target.value)}/>
                            <FormCheck value="L2" checked={l === "L2"} type="radio" className="pink"
                                       name=" L" onChange={e => setL(e.target.value)}/>
                            <FormCheck value="L3" checked={l === "L3"} type="radio" className="grey"
                                       name=" L" onChange={e => setL(e.target.value)}/>
                        </Form></td>
                    </tr>

                    <tr>
                        <td>{t("friday.abbr")}</td>
                        <td><Form>
                            <FormCheck value="M1" checked={m === "M1"} type="radio" className="yellow"
                                       name=" M" onChange={e => setM(e.target.value)}/>
                            <FormCheck value="M2" checked={m === "M2"} type="radio" className="pink"
                                       name=" M" onChange={e => setM(e.target.value)}/>
                            <FormCheck value="M3" checked={m === "M1"} type="radio" className="grey"
                                       name=" M" onChange={e => setM(e.target.value)}/>
                        </Form></td>
                        <td><Form>
                            <FormCheck value="N1" checked={n === "N1"} type="radio" className="yellow"
                                       name=" N" onChange={e => setN(e.target.value)}/>
                            <FormCheck value="N2" checked={n === "N2"} type="radio" className="pink"
                                       name=" N" onChange={e => setN(e.target.value)}/>
                            <FormCheck value="N3" checked={n === "N3"} type="radio" className="grey"
                                       name=" N" onChange={e => setN(e.target.value)}/>
                        </Form></td>
                        <td><Form>
                            <FormCheck value="O1" checked={o === "O1"} type="radio" className="yellow"
                                       name=" O" onChange={e => setO(e.target.value)}/>
                            <FormCheck value="O2" checked={o === "O2"} type="radio" className="pink"
                                       name=" O" onChange={e => setO(e.target.value)}/>
                            <FormCheck value="O3" checked={o === "O3"} type="radio" className="grey"
                                       name=" O" onChange={e => setO(e.target.value)}/>
                        </Form></td>
                    </tr>

                    <tr>
                        <td>{t("saturday.abbr")}</td>
                        <td><Form>
                            <FormCheck value="P1" checked={p === "P1"} type="radio" className="yellow"
                                       name=" P" onChange={e => setP(e.target.value)}/>
                            <FormCheck value="P2" checked={p === "P2"} type="radio" className="pink"
                                       name=" P" onChange={e => setP(e.target.value)}/>
                            <FormCheck value="P3" checked={p === "P3"} type="radio" className="grey"
                                       name=" P" onChange={e => setP(e.target.value)}/>
                        </Form></td>
                        <td><Form>
                            <FormCheck value="Q1" checked={q === "Q1"} type="radio" className="yellow"
                                       name=" Q" onChange={e => setQ(e.target.value)}/>
                            <FormCheck value="Q2" checked={q === "Q2"} type="radio" className="pink"
                                       name=" Q" onChange={e => setQ(e.target.value)}/>
                            <FormCheck value="Q3" checked={q === "Q3"} type="radio" className="grey"
                                       name="Q" onChange={e => setQ(e.target.value)}/>
                        </Form></td>
                        <td><Form>
                            <FormCheck value="R1" checked={r === "R1"} type="radio" className="yellow"
                                       name=" R" onChange={e => setR(e.target.value)}/>
                            <FormCheck value="R2" checked={r === "R2"} type="radio" className="pink"
                                       name=" R" onChange={e => setR(e.target.value)}/>
                            <FormCheck value="R3" checked={r === "R3"} type="radio" className="grey"
                                       name=" R" onChange={e => setR(e.target.value)}/>
                        </Form></td>
                    </tr>

                    <tr>
                        <td>{t("sunday.abbr")}</td>
                        <td><Form>
                            <FormCheck value="S1" checked={s === "S1"} type="radio" className="yellow"
                                       name=" S" onChange={e => setS(e.target.value)}/>
                            <FormCheck value="S2" checked={s === "S2"} type="radio" className="pink"
                                       name=" S" onChange={e => setS(e.target.value)}/>
                            <FormCheck value="S3" checked={s === "S3"} type="radio" className="grey"
                                       name=" S" onChange={e => setS(e.target.value)}/>
                        </Form></td>
                        <td><Form>
                            <FormCheck value="T1" checked={tValue === "T1"} type="radio"
                                       className="yellow" name=" T" onChange={e => setT(e.target.value)}/>
                            <FormCheck value="T2" checked={tValue === "T2"} type="radio" className="pink"
                                       name=" T" onChange={e => setT(e.target.value)}/>
                            <FormCheck value="T3" checked={tValue === "T3"} type="radio" className="grey"
                                       name=" T" onChange={e => setT(e.target.value)}/>
                        </Form></td>
                        <td><Form>
                            <FormCheck value="U1" checked={u === "U1"} type="radio" className="yellow"
                                       name=" U" onChange={e => setU(e.target.value)}/>
                            <FormCheck value="U2" checked={u === "U2"} type="radio" className="pink"
                                       name=" U" onChange={e => setU(e.target.value)}/>
                            <FormCheck value="U3" checked={u === "U3"} type="radio" className="grey"
                                       name=" U" onChange={e => setU(e.target.value)}/>
                        </Form></td>
                    </tr>
                    </tbody>
                </Table>
                <div className="box-buttons">
                    <button className="button go-to" onClick={handleSubmit}>{t("save")}</button>

                </div>
            <Link className="button go-to cancel" to="/my-profile">{t("cancel")}</Link>
            </div>
        </div>
    )
}

export default EditProfile
