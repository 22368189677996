import { useEffect, useState } from 'react'

export const useMatchMedia = (query) => {
    const [doMatch, setDoMatch] = useState(window.matchMedia(query).matches)

    useEffect(() => {
        const media = window.matchMedia(query)
        if (media.matches !== doMatch) {
            setDoMatch(media.matches)
        }

        const listener = () => setDoMatch(media.matches)
        media.addEventListener('change', listener)
    }, [query])

    return doMatch
}
