import React, {useState} from "react";
import {welcomeMessage} from "../utils/welcomeMessage";
import {useUserAuth} from "../context/UserAuthContext";
import axios from "axios";
import {API_URL} from "../helpers/HttpService";
import {useTranslation} from "react-i18next";

export const HomeFirstHelp = () => {

    const {t, i18n} = useTranslation()

    return (
        <div className="homefirst-container">
            <p className="homefirst-p">{t("clickleftto")}...</p>
            <div className="homefirst-table">
                <span className="material-icons">arrow_back</span>
                <p className="homefirst-table-p">{t("gotohome")}</p>
            </div>
            <div className="homefirst-table">
                <span className="material-icons">arrow_back</span>
                <p className="homefirst-table-p">{t("gotoprofile")}</p>
            </div>
            <div className="homefirst-table">
                <span className="material-icons">arrow_back</span>
                <p className="homefirst-table-p">{t("gotosearch")}</p>
            </div>

            <div className="homefirst-table">
                <span className="material-icons">arrow_back</span>
                <p className="homefirst-table-p">{t("gotochat")}</p>
            </div>

            <div className="homefirst-table">
                <span className="material-icons">arrow_back</span>
                <p className="homefirst-table-p">{t("gotohelp")}</p>
            </div>

            <div className="homefirst-table">
                <span className="material-icons">arrow_back</span>
                <p className="homefirst-table-p">{t("gotosettings")}</p>
            </div>

            <br/>
        </div>
    )
}

function HomeFirst() {

    const [popUp, setPopUp] = useState(false)
    const [newUser, setNewUser] = useState(true)
    const {user} = useUserAuth()
    const {t, i18n} = useTranslation()


    const togglePopUp = async () => {
        setPopUp(!popUp)
        if (newUser) {
            await editUser()
            console.log("new user in toggle: ", newUser)
        }
    }

    const editUser = async () => {
        await axios.put(API_URL + `users/${user.uid}`, {"newUser": false}, {
            headers: {
                Authorization: 'Bearer ' + user.accessToken
            }
        }).then((res) => {
            console.log("fulfilled: ", res)
        })
        setNewUser(false)
    }

    return (
        <div>

            {popUp ?
                <HomeFirstHelp/>
                : null}
            <div className="container-contents">
                <div className="box-content homefirst">
                    {welcomeMessage.messageFirst}
                </div>
                <div className="box-content homefirst">
                    <button className="button" onClick={togglePopUp}>{t("whatnow")}?</button>
                </div>
            </div>
        </div>
    )
}

export default HomeFirst