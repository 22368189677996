const productionProfile = {
    apiUrl: "https://api.united-parents.de/"
}

const developmentProfile = {
    apiUrl: "https://dev-api.united-parents.de/"
}

const localProfile = {
    apiUrl: "https://dev-api.united-parents.de/"
}

function getConfig() {
    const {REACT_APP_PROFILE} = process.env
    console.log(`Starting app with profile ${REACT_APP_PROFILE}`)
    console.log(process.env)
    if(REACT_APP_PROFILE === 'production') return productionProfile
    else if (REACT_APP_PROFILE === 'development') return developmentProfile
    else return localProfile
}

export const appConfig = Object.freeze(getConfig())