import React from "react";
import LanguageSelector from "./LanguageSelector";
import {Link} from "react-router-dom";
import {useUserAuth} from "../context/UserAuthContext";
import {useTranslation} from "react-i18next";

/**
 * This is the header which is fixed at the top of the pages
 * contains:
 * logo & option to switch languages
 */

function Header() {
    const {t} = useTranslation();

    const {user} = useUserAuth()

    return (
        <header>
            <div className="testphase"><p>** TESTPHASE **</p></div>
            <div className="box-logo-wrapper">
                <div className="box-logo">
                    {(user.email?.includes("@stud.fra-uas.de") && !user.email?.includes("test1")) || user.email?.includes("@united-parents.de") ?
                        <Link to="/home">
                            <img src="./images/upLogo-noBG.png" className="img up-logo" alt="up-logo-img"></img>
                        </Link> :
                        user.email?.includes("@stud.uni-frankfurt.de") || user.email?.includes("@uni-frankfurt.de") || user.email?.includes("test1@stud.fra-uas.de") ?
                            <Link to="/Ghome">
                                <img src="./images/upLogo-noBG.png" className="img up-logo" alt="up-logo-img"></img>
                            </Link> :
                            <Link to="/my-profile">
                                <img src="./images/upLogo-noBG.png" className="img up-logo" alt="up-logo-img"></img>
                            </Link>
                    }
                </div>
            </div>
            <div className="flex-end-box">
                <a className="feedback-icon-box" href={"mailto:support@united-parents.de"}>
                    <div className="material-icons">feedback</div>
                    <p>{t("feedback")}</p>
                </a>
                <div className="feedback-info-box">
                    <p>{t("feedback-text-short")}</p>
                </div>
                <LanguageSelector/>
            </div>
        </header>
    );
}

export default Header;