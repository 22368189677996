import React from "react"
import MoreHelpListItems from "./MoreHelpListItems"
import {moreHelpData} from "../utils/moreHelpData"

/**
 * List for Help & Finances page
 * contains MoreListItems components filled with content provided by moreHelpData.js
 * @param {*} moreHelpData 
 * @returns Unordered List component with List Items
 */

function createListItems(moreHelpData) {
    return (
        <MoreHelpListItems
            imageSrc={moreHelpData.imageSrc}
            title={moreHelpData.title}
            URL={moreHelpData.URL}
        />
    )
}

function MoreHelpList() {
    return (
        <ul className="list">
            {moreHelpData.data.map(createListItems)}
        </ul>
    )
}

export default MoreHelpList