import React from "react";
import GuAccordion from "../components/GuAccordion";

function GuHelpFinances() {
    return (
        <div className="container-contents">
            <div className="box-content help">
                <div className="box-logo uni-logo">
                    <img src="./images/gu-logo.png" className="img gu-logo-img" alt="logo-img"></img>
                </div>
                <GuAccordion/>
            </div>
        </div>
    )
}

export default GuHelpFinances