import React, {Suspense, useEffect, useState} from "react";
import {useUserAuth} from "../context/UserAuthContext";
import axios from "axios";
import {useTranslation} from "react-i18next";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {API_URL, getData, postData, socket} from "../helpers/HttpService";
import {tooltips} from "../utils/tooltips";
import {OverlayTrigger} from "react-bootstrap";

const ChatUser = (props) => {

    const {user} = useUserAuth()
    const {uid, lastmessage, onClick, checked} = props;

    const [username, setUsername] = useState('')
    const [isBlocked, setBlocked] = useState(false)

    useEffect(async () => {
        await axios.get(API_URL + `users/${props.uid}`, {

            headers: {
                Authorization: 'Bearer ' + user.accessToken
            }
        }).then(res => {

            if (res.data.result == null) {
                setUsername("GELÖSCHT")
                setBlocked(true) // hide chats from deleted users
            } else {
                setUsername(res.data.result.username)

                res.data.result.blockedUsers.forEach((item) => {
                    // check if user is blocked by chatpartner
                    if (item.id === user.uid) {
                        setBlocked(true)
                    }
                })
            }
        }).catch(err => console.log(err))

    }, [])

    const test = () => {
        console.log("lastmsg: ", lastmessage)
        if (!lastmessage.read && lastmessage.sender !== user.uid) {
            socket.emit("messageRead", lastmessage._id)
        }
        onClick(uid, username)
    }

    return (
        <Suspense fallback={<p>loading...</p>}>
            {isBlocked ? null :
                <div className={checked ? "chat-overview checked" : "chat-overview"}
                    // onClick={() => onClick(uid, username)}>
                     onClick={test}>
                    <div className="chat">
                        <img className="round-image"
                             src={`https://firebasestorage.googleapis.com/v0/b/united-parents-hub.appspot.com/o/images%2F${props.uid}?alt=media&token=b7213d01-a694-4828-9b90-e7edb5b92df1`}
                             alt="user-img"
                             onError={(e) => {
                                 if (e.target.src !== "/images/Profile_avatar_placeholder_large.png") {
                                     e.target.onError = null;
                                     e.target.src = '/images/Profile_avatar_placeholder_large.png'
                                 }
                             }}
                        ></img>
                        {checked || lastmessage.read || lastmessage.sender === user.uid ? null :
                            <p className="notification"></p>}
                    </div>
                    <div className="text-wrapper-overview">
                        <p className="user-name">{username}</p>
                        <p className="last-message">{lastmessage.message}</p>
                        <p className="timestamp-overview">{lastmessage.createdAt !== "" ? lastmessage.createdAt.substring(0, 10) === new Date(Date.now()).toISOString().substring(0, 10) ? new Intl.DateTimeFormat('de-DE', {
                            hour: '2-digit',
                            minute: '2-digit'
                        }).format(Date.parse(lastmessage.createdAt)).replace(',', ' ') : new Intl.DateTimeFormat('de-DE', {
                            day: '2-digit',
                            month: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit'
                        }).format(Date.parse(lastmessage.createdAt)).replace(',', ' ') : null}</p>
                    </div>
                </div>
            }
        </Suspense>
    );
}

const Chats = () => {

    const {user} = useUserAuth()
    const {t, i18n} = useTranslation()

    const data = useLocation()
    const navigate = useNavigate()

    const [chatStarted, setChatStarted] = useState(false);
    const [message, setMessage] = useState('');
    const [chatUserUid, setChatUserUid] = useState('');
    const [chatPartner, setChatPartner] = useState('');
    const [messages, setMessages] = useState([]);
    const [UIDList, setUIDList] = useState([]);
    const [blockedList, setBlockedList] = useState([])
    const [drawer, setDrawer] = useState(true)
    const [blocked, setBlocked] = useState(false)

    const initChat = () => {
        setChatStarted(true);
        if (window.matchMedia("(max-width: 769px)").matches) {
            setDrawer(false)
        }
        socket.on("UPDATE", (newmsg) => {
            console.log("UPDATE in INITCHAT: ", newmsg)
            fetchData()
            loadUserList()
        });
        fetchData()
    }

    useEffect(() => {
        socket.on("messageRead", (id) => {
            console.log("MESSAGE READ: ", id)
        })
    }, [])

    // getUIDList
    useEffect(() => {

        if (user) {
            loadUserList()
            // const users = []
            // let tempblocked = []
            //
            // //load list of blocked users
            // axios.get(APP_URL + `users/blockedusers/${user.uid}`, {
            //     headers: {
            //         Authorization: 'Bearer ' + user.accessToken
            //     }
            // }).then((res) => {
            //     tempblocked = res.data.blockedUsers
            //     setBlockedList(res.data.blockedUsers)
            //     // })
            //
            //     //load conversations
            //     getData(`/messages/conversations?user=${user.uid}`)
            //         .then(res => {
            //             res.conversations.forEach(item => {
            //                 console.log("ITEM IN GETCONVERSATIONS: ", item)
            //                 if (tempblocked.length > 0) {
            //                     if (!(tempblocked.some(block => block.id === item.users[0])) && !(tempblocked.some(block => block.id === item.users[1]))) {
            //
            //                         if (item.users[0] !== user.uid && item.users[0] !== null) {
            //                             users.push({
            //                                 "uid": item.users[0],
            //                                 "lastmessage": item.lastmessage,
            //                             })
            //                         } else if (item.users[1] !== user.uid && item.users[1] !== null) {
            //                             users.push({
            //                                 "uid": item.users[1],
            //                                 "lastmessage": item.lastmessage,
            //                             })
            //                         }
            //                     }
            //                 } else {
            //                     if (item.users[0] !== user.uid && item.users[0] !== null) {
            //                         users.push({
            //                             "uid": item.users[0],
            //                             "lastmessage": item.lastmessage,
            //                         })
            //                     } else if (item.users[1] !== user.uid && item.users[1] !== null) {
            //                         users.push({
            //                             "uid": item.users[1],
            //                             "lastmessage": item.lastmessage,
            //                         })
            //                     }
            //                 }
            //
            //
            //             })
            //
            //             // select specific chat if navigated from profile or usersearch
            //             if (data.state !== null && !(tempblocked.some(block => block.id === data.state.receiver))) {
            //                 setChatUserUid(data.state.receiver)
            //                 axios.get(APP_URL + `users/${data.state.receiver}`, {
            //
            //                     headers: {
            //                         Authorization: 'Bearer ' + user.accessToken
            //                     }
            //                 }).then(res => {
            //                     setChatPartner(res.data.result.username)
            //                 }).catch(err => console.log(err))
            //
            //                 let count = 0
            //                 users.forEach(item => {
            //                     if (item.uid === data.state.receiver) {
            //                         count++
            //                     }
            //                 })
            //                 if (count === 0) {
            //                     users.push({
            //                         "uid": data.state.receiver, "lastmessage": {
            //                             "message": "",
            //                             "sender": "",
            //                             "createdAt": "",
            //                             "read": false
            //                         }
            //                     })
            //                 }
            //             }
            //             users.sort((a, b) => {
            //                 return new Date(a.lastmessage.createdAt) - new Date(b.lastmessage.createdAt)
            //             })
            //             users.reverse()
            //             setUIDList(users)
            //             console.log(UIDList)
            //         })
            //         .catch(err => {
            //             console.log(err);
            //         })
            // })

        } else {
            setChatUserUid('')
            setUIDList([])
        }

    }, [user, blocked]);

    useEffect(() => {
        if (drawer) {
            document.getElementById("drawer").style.width = "75%"
        } else {
            document.getElementById("drawer").style.width = "0%"
        }
    }, [drawer])

    useEffect(() => {
        if (chatUserUid !== '')
            initChat(chatUserUid)
        loadUserList()
    }, [chatUserUid]);


    const fetchData = () => {

        getData(`/messages/conversation?sender=${user.uid}&receiver=${chatUserUid}`)
            .then(res => {
                console.log("GETDATA in FETCHDATA", res)
                if (res.conversation.messages) {
                    if (res.conversation.messages !== messages) {
                        setMessages(res.conversation.messages);
                    }
                } else {
                    setMessages([])
                }

            })
            .catch(err => {
                console.log(err);
            })
    }

    const loadUserList = () => {
        const users = []
        let tempblocked = []

        //load list of blocked users
        axios.get(API_URL + `users/blockedusers/${user.uid}`, {
            headers: {
                Authorization: 'Bearer ' + user.accessToken
            }
        }).then((res) => {
            tempblocked = res.data.blockedUsers
            setBlockedList(res.data.blockedUsers)
            // })

            //load conversations
            getData(`/messages/conversations?user=${user.uid}`)
                .then(res => {
                    res.conversations.forEach(item => {

                        if (tempblocked.length > 0) {
                            if (!(tempblocked.some(block => block.id === item.users[0])) && !(tempblocked.some(block => block.id === item.users[1]))) {

                                if (item.users[0] !== user.uid && item.users[0] !== null) {
                                    users.push({
                                        "uid": item.users[0],
                                        "lastmessage": item.lastmessage,
                                    })
                                } else if (item.users[1] !== user.uid && item.users[1] !== null) {
                                    users.push({
                                        "uid": item.users[1],
                                        "lastmessage": item.lastmessage,
                                    })
                                }
                            }
                        } else {
                            if (item.users[0] !== user.uid && item.users[0] !== null) {
                                users.push({
                                    "uid": item.users[0],
                                    "lastmessage": item.lastmessage,
                                })
                            } else if (item.users[1] !== user.uid && item.users[1] !== null) {
                                users.push({
                                    "uid": item.users[1],
                                    "lastmessage": item.lastmessage,
                                })
                            }
                        }


                    })

                    // select specific chat if navigated from profile or usersearch
                    if (data.state !== null && !(tempblocked.some(block => block.id === data.state.receiver))) {
                        setChatUserUid(data.state.receiver)
                        axios.get(API_URL + `users/${data.state.receiver}`, {

                            headers: {
                                Authorization: 'Bearer ' + user.accessToken
                            }
                        }).then(res => {
                            setChatPartner(res.data.result.username)
                        }).catch(err => console.log(err))

                        let count = 0
                        users.forEach(item => {
                            if (item.uid === data.state.receiver) {
                                count++
                            }
                        })
                        if (count === 0) {
                            users.push({
                                "uid": data.state.receiver, "lastmessage": {
                                    "message": "",
                                    "sender": "",
                                    "createdAt": "",
                                    "read": false
                                }
                            })
                        }
                    }
                    users.sort((a, b) => {
                        return new Date(a.lastmessage.createdAt) - new Date(b.lastmessage.createdAt)
                    })
                    users.reverse()
                    setUIDList(users)
                    console.log(UIDList)
                })
                .catch(err => {
                    console.log(err);
                })
        })
    }


    const sendmsg = async () => {

        postData("/conversation/addmsg", {
            message,
            sender: user.uid,
            receiver: chatUserUid,
            read: false
        })
            .then(res => {
                console.log(res)
                const array = [...UIDList]
                array[UIDList.findIndex((item) =>
                    item.uid === chatUserUid
                )].lastmessage = {
                    "createdAt": res.conversation.messages.slice(-1)[0].createdAt,
                    "message": res.conversation.messages.slice(-1)[0].message
                }
                array.sort((a, b) => {
                    return new Date(b.lastmessage.createdAt) - new Date(a.lastmessage.createdAt)

                })
                setUIDList([...array])
                if (data.state !== null) {
                    if (chatUserUid === data.state.receiver) {
                        navigate(data.pathname, {})
                    }
                }
            })
            .catch(err => {
                console.log("error sending: ", err)
            })
    }


    const submitMessage = (e) => {
        e.preventDefault();
        if (message.length > 0) {
            sendmsg()
            setMessage("")

        }
    }

    const handleChatUserClick = (uid, username) => {
        setChatPartner(username)
        setChatUserUid(uid)
    }

    const blockUser = async () => {
        //axios.put(`https://united-parents-api-new-l6hcjtgwsq-ew.a.run.app/users/delete/blockedusers/${user.uid`)
        axios.put(API_URL + `users/blockedusers/${user.uid}`,
            {blockedUsers: chatUserUid}, {
                headers: {
                    Authorization: 'Bearer ' + user.accessToken
                }
            }).then((res) => {
            if (res.status === 200 || res.status === 201) {
                alert("User" + t("userblocked"))
                setChatPartner("")
                setChatUserUid("")
                setChatStarted(false)
                // navigate(data.pathname, {state: "", replace: true})
                setBlocked(!blocked)
            } else {
                alert(t("userblockerror") + "support@united-parents.de")
            }
        })
    }

    return (
        <div className="container-contents">
            <h1>Chats</h1>

            <div className="whole-chat">

                <div id="drawer" className="chat-drawer">
                    <div className="chat-list-box">
                        {UIDList.length > 0 ?
                            UIDList.map(item =>
                                <ChatUser
                                    onClick={handleChatUserClick}
                                    key={item.uid}
                                    uid={item.uid}
                                    lastmessage={item.lastmessage}
                                    checked={item.uid === chatUserUid}
                                    read={item.read}
                                />)
                            : <div className="emptychatlist">
                                <p>{t("findvia")} <Link to="/search-user">{t("search")}</Link>!
                                </p>
                            </div>
                        }
                    </div>
                </div>

                <div className="specific-chat-n-input">
                    <div className="chatpartner">
                        <button className="chat-drawer-btn" onClick={() => setDrawer(!drawer)}>
                            <span className="material-icons">menu</span>
                        </button>
                        {(chatPartner !== "" && chatPartner !== "GELÖSCHT") ?
                            <div className="chatrow">
                                <OverlayTrigger placement="top" overlay={tooltips.tt_toProfile}>
                                    <Link className="chattitle" to={'/userProfile'} state={{uid: chatUserUid}}>
                                        <p className="user-name">{chatPartner}</p>
                                    </Link>
                                </OverlayTrigger>
                                {/*<div className="home-hlp box-icon">*/}
                                <OverlayTrigger placement="top" overlay={tooltips.tt_blockUser}>
                                    <p className="block-btn">
                                        <span className="material-icons" onClick={blockUser}>block</span>
                                    </p>
                                </OverlayTrigger>
                                {/*</div>*/}
                            </div>
                            : <p className="chattitle"></p>}
                    </div>
                    <div className="specific-chat" id="chat-box">
                        <div className="to-remove-and-add">

                            {
                                chatStarted ?
                                    messages.map((ms, index) => <div key={index}>
                                            <img
                                                className={ms.sender === user.uid ? "circle-img round-image-for-chat receiver-image" : "circle-img round-image-for-chat"}
                                                src={`https://firebasestorage.googleapis.com/v0/b/united-parents-hub.appspot.com/o/images%2F${ms.sender}?alt=media&token=b7213d01-a694-4828-9b90-e7edb5b92df1`}
                                                alt="user-img"
                                                onError={(e) => {
                                                    if (e.target.src !== "/images/Profile_avatar_placeholder_large.png") {
                                                        e.target.onError = null;
                                                        e.target.src = '/images/Profile_avatar_placeholder_large.png'
                                                    }
                                                }
                                                }/>
                                            <div className={ms.sender === user.uid ? "message sender" : "message"}>
                                                <p className="message-text">{ms.message}</p>
                                                <p className="timestamp-chat">
                                                    {ms.createdAt.substring(0, 10) === new Date(Date.now()).toISOString().substring(0, 10) ? t("today") + ", " + new Intl.DateTimeFormat('de-DE', {
                                                        hour: '2-digit',
                                                        minute: '2-digit'
                                                    }).format(Date.parse(ms.createdAt)).replace(',', ' ') : new Intl.DateTimeFormat('de-DE', {
                                                        day: '2-digit',
                                                        month: '2-digit',
                                                        hour: '2-digit',
                                                        minute: '2-digit'
                                                    }).format(Date.parse(ms.createdAt)).replace(',', ' ')}</p>

                                            </div>
                                        </div>
                                    ) : null
                            }

                        </div>

                        <div className="white-bar"></div>
                    </div>
                    {chatPartner !== "GELÖSCHT" ?
                        <div className="input-wrapper">
                            <textarea id="input"
                                      className="input"
                                      disabled={!chatStarted}
                                      value={message}
                                      onChange={(e) => setMessage(e.target.value)}
                                      placeholder={t("writemessage")}>

                            </textarea>
                            <button className="send-msg-btn-chat" disabled={!chatStarted} onClick={submitMessage}>
                                <span className="material-icons">forum</span>
                            </button>
                        </div> :
                        <div className="input-wrapper">
                            <textarea id="input"
                                      className="input"
                                      disabled
                                      value={" "}
                                      placeholder={t("writemessage")}>
                            </textarea>
                            <button className="send-msg-btn-chat" disabled>
                                <span className="material-icons">forum</span>
                            </button>
                        </div>}
                </div>
            </div>
        </div>
    );
}
export default Chats;