import React, {useState} from "react";
import {Alert, Button, Card, Form} from 'react-bootstrap'
import {Link, useNavigate} from 'react-router-dom'
import {useUserAuth} from "../context/UserAuthContext"

const Registration = () => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [rePassword, setRePassword] = useState("")
    const {register} = useUserAuth()
    const [error, setError] = useState("")
    const [emailError, setEmailError] = useState("")
    const navigate = useNavigate()

    const handleSubmit = async (e) => {
        e.preventDefault()
        setError(null)
        setEmailError(null)

        if ((email.includes("@stud.fra-uas.de") || email.includes("@stud.uni-frankfurt.de") || email.includes("@uni-frankfurt.de")) && (password === rePassword)) {
            try {
                await register(email, password)
                navigate("/registration2")
                setError(null)
                setEmailError(null)
            } catch (error) {
                console.log(error)
                setError("Bitte wiederhole dein Passwort");
                if (error.code === "auth/email-already-in-use") {
                    setEmailError("Diese Email-Adresse wird bereits verwendet")
                } else {
                    setEmailError("Bitte gib deine Hochschul-Email-Adresse an");
                }
            }
        } else {
            if (password !== rePassword) {
                setError("Passwörter stimmen nicht überein");
            }
            if (!email.includes("@stud.fra-uas.de") && !email.includes("@stud.uni-frankfurt.de") && !email.includes("@uni-frankfurt.de")) {
                setEmailError("Bitte gib deine Hochschul-Email-Adresse an");
            }
        }
    }

    return (
        <div className="content-surface-logreg">
            <div className="container-login-register">
                <div className="box-content">
                    <Card>
                        <Card.Body>
                            <h1 className="text-center mb-4">Registrieren</h1>
                            <div className="box-logo up-logo">
                                <img src="./images/upLogo-noBG.png" className="up-logo" alt="up-logo-img"></img>
                            </div>

                            <Form onSubmit={handleSubmit}>
                                {error ? <Alert variant='danger'>{error}</Alert> : null}
                                {emailError ? <Alert variant='danger'>{emailError}</Alert> : null}
                                <Form.Group id="email">
                                    <Form.Label>E-Mail</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Hochschul-E-Mail-Adresse"
                                        onChange={(e) => setEmail(e.target.value)}/>
                                </Form.Group>

                                <Form.Group id="password">
                                    <Form.Label>Passwort</Form.Label>
                                    <Form.Control
                                        type="password"
                                        placeholder="Passwort"
                                        data-toggle="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}/>
                                </Form.Group>

                                <Form.Group id="rePassword">
                                    <Form.Label>Passwort Wiederholen</Form.Label>
                                    <Form.Control
                                        type="Password"
                                        placeholder="Passwort wiederholen"
                                        value={rePassword}
                                        onChange={(e) => setRePassword(e.target.value)}/>
                                </Form.Group>

                                <Form.Group id="checktermscond">
                                    <Form.Check
                                        required
                                        label={<div>Ich habe die <Link to="/terms">Nutzungsbedingungen</Link> und <Link
                                            to="/dsgvo">Datenschutzerklärung</Link> gelesen, verstanden und stimme ihnen
                                            zu.</div>}
                                        feedback="Du kannst dich nur registrieren, wenn du zustimmst."
                                        feedbackType="invalid"
                                    />
                                </Form.Group>

                                <div className="d-grid gap-2">
                                    <Button className="w-100" type="register">Weiter</Button>
                                </div>
                            </Form>
                        </Card.Body>
                        <div className="w-100 text-center mt-2">
                            Du hast bereits einen Account?<br></br>
                            <Link to="/">Log dich hier ein</Link>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default Registration;
