export const Impressum = () => {
    return (
        <div className="termsconds">
            <h1>Impressum</h1>
            <h1>Angaben gemäß § 5 TMG:</h1>
            <p>Studentin<br/>
                Christina Jones</p>
            <h2>Postanschrift:</h2>
            <p>Tiroler Straße 101<br/>
                60596 Frankfurt am Main</p>
            <h2>Kontakt:</h2>
            <p> Telefon: 015908630005<br/>
                E-Mail: <a href="mailto:christinajones@outlook.de">christinajones@outlook.de</a></p>


        </div>
    )
}