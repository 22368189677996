import React from "react";
import {Link, useLocation} from "react-router-dom";

function Footer() {
    const location = useLocation()

    if (location.pathname !== "/impressum")
        return (<footer>
            <Link to="/impressum">Impressum</Link>
        </footer>)
    else return null
}

export default Footer;