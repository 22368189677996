import React, {useState} from "react";
import {Alert, Button, Card, Form} from 'react-bootstrap'
import {Link, useNavigate} from 'react-router-dom'
import {useUserAuth} from "../context/UserAuthContext"
import {getAuth, sendPasswordResetEmail} from "firebase/auth";
import axios from "axios";
import {welcomeMessage} from "../utils/welcomeMessage";
import {API_URL} from "../helpers/HttpService";


function Login() {
    const {user} = useUserAuth();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const {login} = useUserAuth();
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const [popUp, setPopUp] = useState(false);

    const getUser = async () => {

        try {
            let response = await axios.get(API_URL + `users/${user.uid}`, {

                headers: {
                    Authorization: 'Bearer ' + user.accessToken
                }

            })
            return response.data?.result.newUser


        } catch (err) {
            if (err.response) {
                console.log(err.response.data)
                console.log(err.response.status)
                console.log(err.response.headers)
            } else {
                console.log(`error ${err.message}`)
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setError(null)
        try {
            await login(email, password)
                .then(async () => {
                // if (user.emailVerified) {
                //     console.log("VERIFIED")
                const userNew = await getUser()
                if (userNew) {
                    navigate("/homefirst")
                    setError(null)
                } else {
                    navigate("/my-profile")
                }


                // } else {
                //     console.log("NOT VERIFIED")
                //     setError("Bitte bestätige deine E-Mail-Adresse.")
                // }
            })


        } catch (error) {
            setError("E-Mail und Passwort stimmen nicht überein!")
            console.log(error)
        }
    }

    const auth = getAuth();

    function changePassword() {
        sendPasswordResetEmail(auth, user.email).then(() => {
            alert("Eine E-Mail zum Zurücksetzen deines Passworts wurde versendet!")
        }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            alert(errorCode + " " + errorMessage)
        });
    }

    return (
        <div className="content-surface-logreg">
            {popUp ? <div className="container-login-register">
                    <a className="welcome-a" onClick={() => setPopUp(!popUp)}>Zurück zum Login</a>
                    <div className="box-content"><p>{welcomeMessage.messageShortLogin}</p></div>
                </div> :
                <div className="container-login-register">
                    <a className="welcome-a" onClick={() => setPopUp(!popUp)}>Was ist UNIted Parents?</a>
                    <div className="box-content">
                        <Card>
                            <Card.Body>
                                <h1 className="text-center mb-4">Login</h1>
                                <div className="box-logo up-logo">
                                    <img src="./images/upLogo-noBG.png" className="up-logo-img" alt="up-logo-img"></img>
                                </div>

                                <Form onSubmit={handleSubmit}>
                                    {error ? <Alert variant="danger"><p>{error}</p></Alert> : null}
                                    <Form.Group id="email">
                                        <Form.Label>E-Mail</Form.Label>
                                        <Form.Control
                                            type="email"
                                            placeholder="E-Mail Adresse"
                                            onChange={(e) => setEmail(e.target.value)}/>
                                    </Form.Group>

                                    <Form.Group id="password">
                                        <Form.Label>Passwort</Form.Label>
                                        <Form.Control
                                            type="password"
                                            placeholder="Passwort"
                                            onChange={(e) => setPassword(e.target.value)}/>
                                    </Form.Group>
                                    <a className="forgot-password" href='/resetPassword'>Passwort zurücksetzen</a>
                                    <Button className="w-100" type="register">Login</Button>
                                </Form>
                            </Card.Body>
                            <div className="w-100 text-center mt-2">
                                Du hast noch keinen Account?<br></br>
                                <Link to="/registration">Registriere dich hier</Link>
                            </div>
                        </Card>
                    </div>
                </div>}
            {/*<div className="box-content">*/}
            {/*    <Card>*/}
            {/*        <Card.Body>*/}
            {/*            <h1 className="text-center mb-4">Login</h1>*/}
            {/*            <div className="box-logo up-logo">*/}
            {/*                <img src="./images/upLogo-noBG.png" className="up-logo-img" alt="up-logo-img"></img>*/}
            {/*            </div>*/}

            {/*            <Form onSubmit={handleSubmit}>*/}
            {/*                {error ? <Alert variant="danger"><p>{error}</p></Alert> : null}*/}
            {/*                <Form.Group id="email">*/}
            {/*                    <Form.Label>E-Mail</Form.Label>*/}
            {/*                    <Form.Control*/}
            {/*                        type="email"*/}
            {/*                        placeholder="E-Mail Adresse"*/}
            {/*                        onChange={(e) => setEmail(e.target.value)}/>*/}
            {/*                </Form.Group>*/}

            {/*                <Form.Group id="password">*/}
            {/*                    <Form.Label>Passwort</Form.Label>*/}
            {/*                    <Form.Control*/}
            {/*                        type="password"*/}
            {/*                        placeholder="Passwort"*/}
            {/*                        onChange={(e) => setPassword(e.target.value)}/>*/}
            {/*                </Form.Group>*/}
            {/*                <a className="forgot-password" href='/resetPassword'>Passwort zurücksetzen</a>*/}
            {/*                <Button className="w-100" type="register">Login</Button>*/}
            {/*            </Form>*/}
            {/*        </Card.Body>*/}
            {/*        <div className="w-100 text-center mt-2">*/}
            {/*            Du hast noch keinen Account?<br></br>*/}
            {/*            <Link to="/registration">Registriere dich hier</Link>*/}
            {/*        </div>*/}
            {/*    </Card>*/}
            {/*</div>*/}
            {/*</div>*/}
        </div>
    )
        ;
}

export default Login;