import React, {useEffect, useState} from "react";
import {Form, Card} from 'react-bootstrap';
import axios from "axios";
import {useTranslation} from "react-i18next";
import {Link, useNavigate} from "react-router-dom";
import {API_URL} from "../helpers/HttpService";


/**
 * Page where users can edit their profile
 * @returns edited user info (displayed at My Profile page)
 */

function EditHomePage() {

    const [content1, setContent1] = useState('');
    const [content1en, setContent1en] = useState('');
    const [content2Title, setContent2Title] = useState('');
    const [content2, setContent2] = useState('');
    const [content2link, setContent2Link] = useState('');
    const [content2en, setContent2en] = useState('');
    const [content3Title, setContent3Title] = useState('');
    const [content3, setContent3] = useState('');
    const [content3link, setContent3Link] = useState('');
    const [content3en, setContent3en] = useState('');
    const [content4Title, setContent4Title] = useState('');
    const [content4, setContent4] = useState('');
    const [content4link, setContent4Link] = useState('');
    const [content4en, setContent4en] = useState('');
    const [error,
        setError] = useState("");
    const navigate = useNavigate()
    const {t, i18n} = useTranslation()


    useEffect(() => {


        const getContent = async () => {
            try {
                let response = await axios.get(API_URL + `homePage/`, {})

                console.log(response)

                setContent1(response.data
                    ?.[0].content1)
                setContent1en(response.data
                    ?.[0].content1en)
                setContent2Title(response.data
                    ?.[0].content2title)
                setContent2Link(response.data
                    ?.[0].content2link)
                setContent2(response.data
                    ?.[0].content2)
                setContent2en(response.data
                    ?.[0].content2en)
                setContent3Title(response.data
                    ?.[0].content3title)
                setContent3Link(response.data
                    ?.[0].content3link)
                setContent3(response.data
                    ?.[0].content3)
                setContent3en(response.data
                    ?.[0].content3en)
                setContent4Title(response.data
                    ?.[0].content4title)
                setContent4Link(response.data
                    ?.[0].content4link)
                setContent4(response.data
                    ?.[0].content4)
                setContent4(response.data
                    ?.[0].content4)


            } catch (err) {
                if (err.response) {
                    console.log(err.response.data)
                    console.log(err.response.status)
                    console.log(err.response.headers)
                } else {
                    console.log(`error ${err.message}`)
                }
            }
        }
        getContent();


    }, []);

    const ContentData = {

        content1: content1,
        content1en: content1en,
        content2title: content2Title,
        content2: content2,
        content2en: content2en,
        content2link: content2link,
        content3title: content3Title,
        content3: content3,
        content3en: content3en,
        content3link: content3link,
        content4title: content4Title,
        content4: content4,
        content4en: content4en,
        content4link: content4link,

    };
    //http://0.0.0.0:4000
    //https://united-parents-api-new-l6hcjtgwsq-ew.a.run.app/users/${user.uid}

    const editContent = async () => {
        let res = axios.put(API_URL + `homePage/`, ContentData)
        console.log(res);
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setError("")
        try {
            editContent().then(() => {
                alert(t("changessaved"))
                navigate("/home")
            })

        } catch (err) {
            setError(err.message);
        }
    }


    return (
        <div className="container-contents">
            <div className="box-content edit-profile">
                <Card>
                    <Card.Body>
                        <h1 className="text-center mb-4">{t("edithomepage")}</h1>


                        <Form onSubmit={handleSubmit}>

                            <Form.Group className="mb-3" id="content1">
                                <Form.Label>{t("welcometext")}</Form.Label>
                                <Form.Control
                                    value={content1}
                                    type="content1"
                                    as="textarea"
                                    placeholder="Willkommenstext"
                                    rows={7}
                                    onChange={(e) => setContent1(e.target.value)}/>
                            </Form.Group>

                            <Form.Group className="mb-3" id="content1en">
                                <Form.Label>{t("welcometext")}: {t("english")} ({t("optional")})</Form.Label>
                                <Form.Control
                                    value={content1en}
                                    type="content1en"
                                    as="textarea"
                                    placeholder={t("welcometext") + ": " + t("english")}
                                    rows={7}
                                    onChange={(e) => setContent1en(e.target.value)}/>
                            </Form.Group>

                            <Form.Group id="content2">
                                <Form.Label>{t("content")} 2: {t("title")} </Form.Label>
                                <Form.Control
                                    value={content2Title}
                                    type="content2"
                                    placeholder="Inhalt 2 Titel"
                                    onChange={(e) => setContent2Title(e.target.value)}/>
                            </Form.Group>

                            <Form.Group id="content2link">
                                <Form.Label>{t("content")} 2: {t("link")}</Form.Label>
                                <Form.Control
                                    value={content2link}
                                    type="content2link"
                                    placeholder="Inhalt 2 link"
                                    onChange={(e) => setContent2Link(e.target.value)}/>
                            </Form.Group>

                            <Form.Group className="mb-3" id="content2">
                                <Form.Label>{t("content")} 2</Form.Label>
                                <Form.Control
                                    value={content2}
                                    type="content1"
                                    as="textarea"
                                    placeholder="Inhalt 2"
                                    rows={5}
                                    onChange={(e) => setContent2(e.target.value)}/>
                            </Form.Group>

                            <Form.Group className="mb-3" id="content2en">
                                <Form.Label>{t("content")} 2: {t("english")} ({t("optional")})</Form.Label>
                                <Form.Control
                                    value={content2en}
                                    type="content1"
                                    as="textarea"
                                    placeholder={t("content") + " 2: " + t("english")}
                                    rows={5}
                                    onChange={(e) => setContent2en(e.target.value)}/>
                            </Form.Group>


                            <Form.Group id="content3title">
                                <Form.Label>{t("content")} 3: {t("title")} </Form.Label>
                                <Form.Control
                                    value={content3Title}
                                    type="content3"
                                    placeholder="Inhalt 3 Titel"
                                    onChange={(e) => setContent3Title(e.target.value)}/>
                            </Form.Group>

                            <Form.Group id="content3link">
                                <Form.Label>{t("content")} 3: {t("link")}</Form.Label>
                                <Form.Control
                                    value={content3link}
                                    type="content2link"
                                    placeholder="Inhalt 3 link"
                                    onChange={(e) => setContent3Link(e.target.value)}/>
                            </Form.Group>

                            <Form.Group className="mb-3" id="content3">
                                <Form.Label>{t("content")} 3</Form.Label>
                                <Form.Control
                                    value={content3}
                                    type="content1"
                                    as="textarea"
                                    placeholder="Inhalt 3"
                                    rows={5}
                                    onChange={(e) => setContent3(e.target.value)}/>
                            </Form.Group>

                            <Form.Group className="mb-3" id="content3en">
                                <Form.Label>{t("content")} 3: {t("english")} ({t("optional")})</Form.Label>
                                <Form.Control
                                    value={content3en}
                                    type="content3"
                                    as="textarea"
                                    placeholder={t("content") + " 3: " + t("english")}
                                    rows={5}
                                    onChange={(e) => setContent3en(e.target.value)}/>
                            </Form.Group>

                            <Form.Group id="content4title">
                                <Form.Label>{t("content")} 4: {t("title")} </Form.Label>
                                <Form.Control
                                    value={content4Title}
                                    type="content4"
                                    placeholder="Inhalt 4 Titel"
                                    onChange={(e) => setContent4Title(e.target.value)}/>
                            </Form.Group>

                            <Form.Group id="content3link">
                                <Form.Label>{t("content")} 4: {t("link")}</Form.Label>
                                <Form.Control
                                    value={content4link}
                                    type="content4link"
                                    placeholder="Inhalt 4 link"
                                    onChange={(e) => setContent4Link(e.target.value)}/>
                            </Form.Group>

                            <Form.Group className="mb-3" id="content4">
                                <Form.Label>{t("content")} 4</Form.Label>
                                <Form.Control
                                    value={content4}
                                    type="content4"
                                    as="textarea"
                                    placeholder="Inhalt 4"
                                    rows={5}
                                    onChange={(e) => setContent4(e.target.value)}/>
                            </Form.Group>

                            <Form.Group className="mb-3" id="content4en">
                                <Form.Label>{t("content")} 4: {t("english")} ({t("optional")})</Form.Label>
                                <Form.Control
                                    value={content4en}
                                    type="content4"
                                    as="textarea"
                                    placeholder={t("content") + " 4: " + t("english")}
                                    rows={5}
                                    onChange={(e) => setContent4en(e.target.value)}/>
                            </Form.Group>

                            <div className="box-buttons">
                                <button className="button go-to" type="submit">{t("save")}</button>
                            </div>
                        </Form>
                        <Link className="button go-to cancel" to="/home">{t("cancel")}</Link>
                    </Card.Body>
                </Card>
            </div>

        </div>

    )
}

export default EditHomePage